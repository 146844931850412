.product-enquiry-management-container {
  position: relative;
  bottom: 30px; /* Adjust this value according to your design */
}

.product-searchEnquiries {
  display: flex;
  justify-content: space-between;
  background: rgba(112, 250, 203, 0.3);
  padding: 22px;
  border-radius: 10px;
  align-items: center;
  margin-top: 25px;
}

.product-mainSearch {
  background-color: #ffffff;
  display: flex;
  justify-items: center;
  padding: 10px;
  border-radius: 10px;
  width: 50%;
  height: 20px;
  border: 1px solid black;
}

.product-searchBox {
  outline: none;
  border: none;
  padding-left: 10px;
  width: 100%;
}

/*table */

.product-content-table {
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 0.9em;
  min-width: 100%;
  overflow: hidden;
  margin-top: 2em !important;
}

.product-content-table thead tr {
  background: rgba(0, 147, 211, 1);
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.product-content-table th {
  padding: 12px 15px;
  margin-left: 10px;
}

/* Apply properties to specific headers */

.product-content-table th:nth-child(3),
.product-content-table td:nth-child(10) {
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Display ellipsis for overflow text */
}

.product-content-table td:nth-child(2) a {
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Display ellipsis for overflow text */
}

.product-content-table td {
  padding: 12px 15px;
  font-size: 0.84em;
}

.product-custom-row td {
  padding: 3px 15px;
  font-size: 0.84em;
  background-color: #0093d333;
}

.product-content-table tbody tr {
  border-bottom: 1px solid rgba(0, 147, 211, 1);
}

.product-content-table th:nth-child(9),
.product-content-table td:nth-child(5),
.product-content-table td:nth-child(9) {
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Display ellipsis for overflow text */
}

.product-content-table input[type='checkbox'] {
  width: 17px; /* Adjust width as needed */
  height: 20px; /* Adjust height as needed */
  margin-left: 0px;
}

.product-grade-head {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-status-open {
  background: #63a47c;
  border-radius: 20px;
  color: white;
  padding: 2px;
  font-size: 12px;
  display: inline-block;
  width: 60px;
  text-align: center;
}

.showCatalogue {
  background: #cb5656;
}

.product-status-close {
  display: inline-block;
  background: red;
  border-radius: 20px;
  color: white;
  padding: 2px;
  font-size: 12px;
  width: 60px;
  text-align: center;
}

.product-status-process {
  background: #e8ab43;
  border-radius: 20px;
  color: white;
  padding: 2px;
  font-size: 12px;
  display: inline-block;
  width: 60px;
}

.product-edit-table {
  width: 18px;
  height: 20px;
  cursor: pointer;
}

/* Pagination CSS */
.product-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 22px; */
  margin-top: 7em;
  gap: 15px;
}

.product-pagination-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px;
  border: 1px solid black;
  font-size: 18px;
}

.product-pagination-button:hover {
  background-color: #d5fef0; /* Change color as needed */
}

.product-pagination-button:active {
  background-color: #999; /* Change color as needed */
}

.product-pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
