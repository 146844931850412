.sidenavbar {
  background-color: #0458ab;
  width: 100%;
  height: 100vh;
}

.sidenavbar-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.title {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

.menu-list {
  height: calc(100vh - 236px);
  overflow-y: auto;
  padding: 0;
  padding: 0px 15px;
}

.menu-item {
  border: 1px solid green;
  list-style-type: none;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.menu-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #70facb;
  border-radius: 10px;
  padding: 5px 5px;
}

.nav-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
}

.menu-item-icon {
  height: 25px;
}

.dropdown-button {
  background-color: transparent;
  border: none;
}

.nav-link.active,
.nav-link-user.active {
  color: #70facb;
  font-weight: bold;
  position: relative;
}

.nav-link.active::before,
.nav-link-user.active::before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  height: 100%;
  width: 4px;
  background-color: #70facb;
}

.submenu {
  background-color: #2589b5;
  list-style: none;
  padding: 10px 2%;
  border-radius: 10px;
}

.sub-nav-link {
  margin-bottom: 5px;
}
