@font-face {
  font-family: poppins-bold;
  src: url(../assets/fonts/Poppins-Bold.ttf);
}
@font-face {
  font-family: poppins-regular;
  src: url(../assets/fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: poppins-semibold;
  src: url(../assets/fonts/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: roboto-bold;
  src: url(../assets/fonts/Roboto-Bold.ttf);
}
@font-face {
  font-family: lato-regular;
  src: url(../assets/fonts/Lato-Regular.ttf);
}
