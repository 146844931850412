.custom-paper {
  padding: 1.5rem;
  border-radius: 15px;
}

.grid-padding-right {
  padding-right: 8px;
}

.grid-padding-left {
  padding-left: 8px;
}

.field-box {
  margin-bottom: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.product-info-box {
  padding: 1rem;
}

.product-info-header {
  display: flex;
  justify-content: space-between;
}

.product-code,
.entry-date,
.warehouse-location {
  font-size: 14px;
}

.product-info-right {
  text-align: right;
}

.catalogue-chip {
  display: inline-block;
  margin-left: 0.5rem;
}

.success-chip {
  background-color: #e0ffe0;
  color: #007f00;
}

.last-edited {
  font-size: 12px;
  color: gray;
}

.upload-images-box {
  padding: 1rem;
}

.upload-title {
  font-size: 16px;
  margin-bottom: 1rem;
}

.upload-dropzone {
  padding: 2rem;
  border: 2px dashed #1976d2;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-dropzone:hover {
  background-color: #e3f2fd;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-text {
  margin-top: 0.5rem;
}

.uploaded-images-box {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.image-container {
  position: relative;
  width: 120px;
  height: 120px;
}

.image-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.image-actions {
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  gap: 4px;
}

.replace-icon,
.delete-icon {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
}

.buttons-container {
  margin-top: 2rem;
  gap: 1rem;
}

.discard-button {
  margin-left: 1rem;
}

.image-modal .modal-content {
  position: relative;
  width: 80%;
  max-width: 500px;
  margin: auto;
  padding: 1rem;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.modal-close-button {
  position: absolute;
  top: 0;
  right: 0;
}

.modal-image-container {
  margin-top: 1rem;
}

.modal-image {
  width: 100%;
  border-radius: 8px;
}
