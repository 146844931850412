
 .gridItem {
  background-color: #e5fef6;
  border-radius: 20px;
  height: 20vh; 
  width: 240px;
  padding: 10px;
  box-shadow: 0px 4px 4px 0px #00000040; 
  border: 1px solid #0abab7;
  cursor: pointer;
  text-align: center;
}

.gridItem p {
  margin: 0; 
}

.contentWrapper {
  text-align: center;
  margin-top: 1.8em;
}

.circle-img {
  margin: 0 auto;
  margin-top: -1.8em;
}

.heading {
  margin: 0 auto;
}

.contentWrapper img {
  width: 55px; 
  height: 55px; 
}

.contentWrapper hr {
  border: none;
  border-top: 1px solid #0abab7; 
  margin-top: 0.5em;
}

.contentWrapper span {
  font-size: 20px; 
  font-weight: lighter; 
  margin-right: 15px; 
  display: inline-block; 
  vertical-align: middle; 
}

.contentWrapper p {
  font-weight: normal; 
  margin-top: 10px;
}

.contentWrapper h2 {
  text-align: center;
  font-size: 26px;
}  
